import { CommonModule, registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, inject, LOCALE_ID, NgModule, provideAppInitializer } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { SEATS_QUANTITY_LIMIT } from '@mazboletos/shared/core/base-component';
import { SessionDataAccessModule } from '@mazboletos/shared/data-access/session';

import localeMx from '@angular/common/locales/es-MX';
import { authInitializer, AuthService, AuthStore } from '@mazboletos/selling-point/auth/data-access';
import {
  IfHasRoleDirective,
  IsAuthenticatedGuard,
  IsAuthenticatedGuardModule,
  isNotAuthenticatedGuard,
} from '@mazboletos/selling-point/auth/util';
import { EventDataAccessModule } from '@mazboletos/shared/data-access/event';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import { EventSeriesApiService } from '@mazboletos/customer-event-data-access';

registerLocaleData(localeMx, 'es-MX');

@NgModule({
  imports: [
    EventDataAccessModule,
    CommonModule,
    IsAuthenticatedGuardModule,
    SessionDataAccessModule,
    IfHasRoleDirective,
  ],
  exports: [],
  providers: [
    provideRouter(
      [
        {
          path: '',
          loadComponent: async () => (await import('./shell.component')).ShellComponent,
          children: [
            {
              path: `${RouteUtils.SignIn}`,
              loadChildren: async () =>
                (await import('@mazboletos/selling-point/auth/feature/sign-in')).SignInFeatureModule,
              canActivate: [isNotAuthenticatedGuard],
            },
            {
              path: `${RouteUtils.Dashboard}`,
              loadChildren: async () =>
                (await import('@mazboletos/selling-point/dashboard/feature')).DashboardFeatureModule,
              canActivate: [IsAuthenticatedGuard],
            },
            {
              path: `${RouteUtils.Checkout}`,
              loadChildren: async () =>
                (await import('@mazboletos/selling-point/checkout/feature/shell')).CheckoutShellModule,
              canActivate: [IsAuthenticatedGuard],
            },
            {
              path: '**',
              pathMatch: 'full',
              redirectTo: `/${RouteUtils.Dashboard}/${RouteUtils.Events}`,
            },
          ],
        },
      ],
      withComponentInputBinding()
    ),
    AuthStore,
    provideAppInitializer(() => {
      const initializerFn = authInitializer(inject(AuthService), inject(AuthStore), inject(Router));
      return initializerFn();
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useFactory: (): MatFormFieldDefaultOptions => ({
        appearance: 'fill',
      }),
    },
    {
      provide: SEATS_QUANTITY_LIMIT,
      useValue: 36,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-MX',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '$',
    },
    EventSeriesApiService,
  ],
})
export class SellingPointShellFeatureModule {}
