import { enableProdMode, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideImageKitLoader } from '@angular/common';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthInterceptor } from '@mazboletos/auth-interceptor';
import { JsonApiInterceptor } from '@mazboletos/shared/core/http/interceptors';
import { QueryParamsHandlerInterceptorInterceptor } from '@mazboletos/shared/core/http/query-params-handler-interceptor';
import { AddSeatsManagerQueryInterceptor } from '@mazboletos/selling-point/seats-manager/data-access/add-seats-manager-query';
import { AnonymousSessionInterceptor } from '@mazboletos/shared/core/http/anonymous-session-interceptor';
import { APP_CONFIG } from '@mazboletos/selling-point/core/config';
import { FALLBACK_ROUTE } from '@mazboletos/shared/core/fallback-route';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import {
  NUMBER_OF_SEATS_SELECTED_PER_TABLE,
  SVG_SEAT_CLASS,
  SVG_SEAT_COURTESY_CLASS,
  SVG_SEAT_ORPHAN_CLASS,
  SVG_SEAT_RESALE_CLASS,
  SVG_SEAT_SELECTED_CLASS,
  SVG_TABLE_SELECTOR,
  SVG_UNAVAILABLE_CLASS,
  VENUE_CONTEXT,
} from '@mazboletos/shared/ui/seat-selection/tokens';
import { bootstrapApplication, BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { SellingPointShellFeatureModule } from '@mazboletos/selling-point/shell/feature';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppComponent } from './app/app.component';
import { EXTENSION_DIALOG } from '@mazboletos/shared/data-access/session';
import { API_URL } from 'core-tokens';

Sentry.init({
  dsn: 'https://8cd9d048b2a849dcb0bc832d7cd70c08@o4505163412602880.ingest.sentry.io/4505298680152064',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/(http|https):\/\/(.*).mazboletos.com/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      SellingPointShellFeatureModule,
      StoreModule.forRoot(
        {},
        {
          metaReducers: !environment.production ? [] : [],
          runtimeChecks: {
            strictActionImmutability: true,
            strictStateImmutability: true,
          },
        }
      ),
      EffectsModule.forRoot([]),
      !environment.production ? StoreDevtoolsModule.instrument() : [],
      StoreRouterConnectingModule.forRoot()
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    provideImageKitLoader(environment.imageKitUrl),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const traceService = inject(Sentry.TraceService);
      return Promise.resolve();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: JsonApiInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: QueryParamsHandlerInterceptorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AddSeatsManagerQueryInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AnonymousSessionInterceptor,
    },
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: API_URL,
      useValue: process.env.MZB_API_URL,
    },
    {
      provide: FALLBACK_ROUTE,
      useValue: RouteUtils.Dashboard,
    },
    {
      provide: SVG_SEAT_CLASS,
      useValue: 's',
    },
    {
      provide: SVG_SEAT_COURTESY_CLASS,
      useValue: 'c',
    },
    {
      provide: SVG_TABLE_SELECTOR,
      useValue: 'MESAS',
    },
    {
      provide: SVG_SEAT_SELECTED_CLASS,
      useValue: 'selected',
    },
    {
      provide: SVG_SEAT_ORPHAN_CLASS,
      useValue: 'orphan',
    },
    {
      provide: SVG_UNAVAILABLE_CLASS,
      useValue: 'u',
    },
    {
      provide: SVG_SEAT_RESALE_CLASS,
      useValue: 'resale',
    },
    {
      provide: NUMBER_OF_SEATS_SELECTED_PER_TABLE,
      useValue: 2,
    },
    {
      provide: VENUE_CONTEXT,
      useValue: 'panel_venue_handling',
    },
    provideClientHydration(),
    provideAnimations(),
    {
      provide: EXTENSION_DIALOG,
      useValue: null,
    },
  ],
}).catch((err) => console.error(err));
